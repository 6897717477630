import endpoints from "../../lib/endpoints";
import { getRequestNew } from "../../lib/fetchClient";
import { baseUrl } from "../../lib/util";

export const getRelatedCourse = async (params) => {
  try {
    return await getRequestNew(
      baseUrl + endpoints.relatedCourse,
      {
        lang: params?.lang,
      },
      true,
      false
    );
  } catch (error) {
    console.log(error);
  }
};

export const getClients = async (params) => {
  try {
    return await getRequestNew(
      baseUrl + endpoints.getClients,
      {
        lang: params?.lang,
      },
      true,
      false
    );
  } catch (error) {
    console.log(error);
  }
};

export const getWhyUs = async (params) => {
  try {
    return await getRequestNew(
      baseUrl + endpoints.getWhyUs,
      {
        lang: params?.lang,
      },
      true,
      false
    );
  } catch (error) {
    console.log(error);
  }
};

export const getFooterMenus = async (params) => {
  try {
    return await getRequestNew(
      baseUrl + endpoints.footerMenus,
      {
        lang: params?.lang,
      },
      true,
      false
    );
  } catch (error) {
    console.log(error);
  }
};

export const getHeaderMenus = async (params) => {
  try {
    return await getRequestNew(
      baseUrl + endpoints.headerMenus,
      {
        lang: params?.lang,
      },
      true,
      false
    );
  } catch (error) {
    console.log(error);
  }
};
