"use client";
import React from "react";
import { useDictionary } from "../../context/dictionaryProvider";
import { PhoneInput } from "react-international-phone";

import "react-international-phone/style.css";

const PhoneField = ({
  label,
  isInvalid,
  errorMessage,
  name,
  value,
  onChange,
}) => {
  const dict = useDictionary();
  return (
    <div className="mb-4" data-nosnippet>
      <label className="block text-sm font-semibold leading-6 text-black-900 mb-1 capitalize">
        {label}
      </label>
      <PhoneInput
        name={name}
        placeholder={dict?.form?.placeholders?.typeHere}
        className="border-b-1"
        inputClassName="!border-none"
        value={value}
        onChange={onChange}
        defaultCountry={"ae" || "us"}
        countrySelectorStyleProps={{
          buttonClassName: "!border-none",
          dropdownStyleProps: {
            className: "!z-[999] w-full",
          },
        }}
      />
      {errorMessage && isInvalid && (
        <div className="group-data-[has-helper=true]:flex relative flex-col gap-1.5 p-0 mt-2">
          <div className="text-tiny text-red-500">{errorMessage}</div>
        </div>
      )}
    </div>
  );
};

PhoneField.displayName = "PhoneField";

export default PhoneField;
